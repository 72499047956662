<template lang="pug">
article.wide-preview
  router-link(:to='{ name: "BlogPost", params: { uid: latestPost.uid } }')
    .post-wrapper
      prismic-image.post-wrapper__image(:field='latestPost.data.image')
      .post-wrapper__infos
        h3.post-wrapper__infos__title {{ latestPost.data.title[0].text }}
        span.post-wrapper__infos__date {{ latestPost.first_publication_date | formatDate }}
        p.post-wrapper__infos__content {{ shrinkEllipsis(previewContentText, maxTextLength) }}
          span.read-more Lire la suite..
</template>

<script>
import { shrinkEllipsis } from '@/filters/shrinkEllipsis'
import { formatDate } from '@/filters/dateFormatter.js'

export default {
  name: 'BlogPostPreviewLatest',
  filters: { shrinkEllipsis, formatDate },
  props: {
    latestPost: {
      type: Object,
      required: true
    }
  },
  computed: {
    maxTextLength() {
      if (this.$store.state.windowWidth > 900) {
        return this.$store.state.windowWidth * 0.5
      } else if (this.$store.state.windowWidth > 800) {
        return this.$store.state.windowWidth * 0.5
      } else if (this.$store.state.windowWidth > 600) {
        return this.$store.state.windowWidth * 0.4
      } else if (this.$store.state.windowWidth > 400) {
        return this.$store.state.windowWidth * 0.4
      } else if (this.$store.state.windowWidth > 300) {
        return this.$store.state.windowWidth * 0.3
      }
      return this.$store.state.windowWidth
    },
    previewContentText() {
      return this.latestPost.data.body
        .filter((slice) => slice.slice_type === 'content_')
        .map((slice) => slice.primary.content1)[0]
        .map((content) => content.text)
        .join(' ')
    }

    // if (this.$store.state.windowWidth < 500) {

    // } else {
    //   return this.$store.state.windowWidth * 0.8
    // }
    // }
  },
  methods: {
    shrinkEllipsis(value, length) {
      return shrinkEllipsis(value, length)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

.wide-preview {
  .post-wrapper {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    height: 200px;
    position: relative;

    @include medium-screen {
      height: 400px;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover; /*magic*/
    }

    &__infos {
      width: 60%;
      height: fit-content;
      background-color: $white;
      position: absolute;
      top: 0;
      left: 10%;
      border-radius: 0 0 20px 20px;
      padding: 10px;
      max-height: 90%;

      &__title {
        font-size: 1rem;
        color: $darkBlue;
        margin-bottom: 0.5rem;
        line-height: 1.3rem;
      }
      &__author {
        font-size: 0.8rem;
        color: rgba($darkBlue, 0.5);
        font-family: 'Libre Franklin', sans-serif;
        margin-right: 0.5rem;
      }
      &__date {
        font-size: 0.8rem;
        color: rgba($darkBlue, 0.5);
        font-family: 'Libre Franklin', sans-serif;
      }
      &__content {
        margin-top: 0.5rem;
        font-size: 0.8rem;
        line-height: 1.2rem;
        color: $darkBlue;
      }

      @include medium-screen {
        width: 50%;
        padding: 20px;

        &__title {
          font-size: 2rem;
          line-height: 2.3rem;
        }

        &__content {
          font-size: 1rem;
          line-height: 1.6rem;
        }
      }
      @include large-screen {
        padding: 30px;
      }
    }
  }

  .read-more {
    font-weight: 600;
  }
}
</style>
